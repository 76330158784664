<template>
  <div class="news main-wrapper">
    <img
      v-for="img in imageList"
      @click="handleLink(img)"
      :key="img.imgKey"
      :src="img.imgUrl"
    />
  </div>
</template>

<script>
import { getCosUrl } from "../utils";
export default {
  name: "news",
  data() {
    return {
      imageList: [
        {
            imgKey: "/news/1.webp",
            imgUrl: "",
            link: "https://finance.sina.cn/sx/2023-08-02/detail-imzeuppa4221006.d.html?from=wap",
            type: "blank",
        },
        {
            imgKey: "/news/2.webp",
            imgUrl: "",
            link: "https://mp.weixin.qq.com/s/QsDjh0iEWqe35-YCM6h6FA",
            type: "blank",
        },
        {
            imgKey: "/news/3.webp",
            imgUrl: "",
            link: "https://mp.weixin.qq.com/s/BvLJQKeT1Zj_C9HDOltqUQ",
            type: "blank",
        },
        {
            imgKey: "/news/4.webp",
            imgUrl: "",
            link: "https://finance.sina.com.cn/sx/2023-08-25/doc-imzimcwc5487593.shtml",
            type: "blank",
        },
        {
            imgKey: "/news/5.webp",
            imgUrl: "",
            link: "https://health.ifeng.com/c/8SXE6QwmtqB",
            type: "blank",
        },
        {
            imgKey: "/news/6.webp",
            imgUrl: "",
            link: "/ContactUs",
            type: "navigator",
        },
      ],
    };
  },
  watch: {
    "$store.state.device": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.initImage();
            }
        },
        deep: true
    },
    "$store.state.language": {
        handler(newVal, oldVal){
            if(newVal != oldVal){
                this.initImage();
            }
        },
        deep: true
    }
  },
  mounted() {
    this.initImage();
  },
  methods: {
    handleLink(img) {
      if (img.type === "blank") window.open(img.link, "_blank");
      else this.$router.push(img.link);
    },
    initImage(){
        const imgList = getCosUrl(this.imageList.map(item => { return item.imgKey }));
        this.imageList.forEach((element, index) => {
            element.imgUrl = imgList[index];
        })
    },
  },
};
</script>

<style lang="less">
div.news {
  img {
    cursor: pointer;
  }
}
</style>
